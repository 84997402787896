import React from 'react';
import './App.css';
import { useTranslation } from 'react-i18next';

import { Routes, Route, Outlet } from "react-router-dom";
import Page404 from './pages/404/404';
import Home from './pages/Home';
import PartyPage from './pages/partyView/PartyPage';
import Search from './pages/Search';
import UserRegistration from './pages/UserRegistration/UserRegistration';
import BookingPage from './features/booking/BookingPage';
import BookingDonePage from './features/booking/bookingDone/BookingDonePage';
import BookingConfirmationPage from './features/booking/BookingConfirmationPage/BookingConfirmationPage';
import BookingCompletePage from './features/booking/BookingCompletePage';
import Header from './components/header/Header'
import Footer from './components/footer/Footer'

import * as SiteRoutes from './app/Routes';
import Activation from "./pages/Activation";
import CompanyRegistration from "./pages/CompanyRegistration/CompanyRegistration";
import {ModalWindowProvider} from "./components/modal/ModalWindowContext";
import {COMPANY_REGISTRATION_ROUTE} from "./app/Routes";

const Layout = () => {

  const { t } = useTranslation();

    const footerLinks = [
        {
            text: t('Terms & Conditions'),
            href: '/Terms-and-conditions.html',
            itemClassName: '',
            linkClassName: ''
        },
        {
            text: t('Register company'),
            href: COMPANY_REGISTRATION_ROUTE,
            itemClassName: '',
            linkClassName: ''
        }
    ]

  return (
        <ModalWindowProvider
          // isClosing={true}
          // isShow={true}
          // title={'test modal'}
          // content={
          //   '<div>' +
          //   '<ul>' +
          //   '<li>test</li><li>test</li><li>test</li><li>test</li><li>test</li><li>test</li>' +
          //   '<li>test</li><li>test</li><li>test</li><li>test</li><li>test</li><li>test</li>' +
          //   '<li>test</li><li>test</li><li>test</li><li>test</li><li>test</li><li>test</li>' +
          //   '</ul>' +
          //   '</div>'
          // }
          // cancelButton={{ text: 'CANCEL'}}
          // icon={'info'}
        >
          <Header />
          <Outlet />
          <Footer links={footerLinks} />
        </ModalWindowProvider>
  )
}


const App = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={SiteRoutes.PARTY_BOOK_DONE_ROUTE} element={<BookingDonePage />} />
        <Route path={SiteRoutes.PARTY_BOOK_ROUTE} element={<BookingPage />} />
        <Route path={SiteRoutes.PARTY_ROUTE} element={<PartyPage />} />
        <Route path={SiteRoutes.SEARCH_ROUTE} element={<Search />} />
        <Route path={SiteRoutes.USER_REGISTRATION_ROUTE} element={<UserRegistration />} />
        <Route path={SiteRoutes.COMPANY_REGISTRATION_ROUTE} element={<CompanyRegistration />} />
        <Route path={SiteRoutes.ACTIVATION_ROUTE} element={<Activation />} />
        <Route path={SiteRoutes.BOOKING_CONFIRMATION_ROUTE} element={<BookingConfirmationPage />} />
        <Route path={SiteRoutes.BOOKING_CONFIRMATION_DONE_ROUTE} element={<BookingCompletePage />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default App;

import styles from './PartyTag.module.css';

type PartyTagsProps = {
    tags: string[];
}

const PartyTag = ({ tags }: PartyTagsProps) => {
    return (
        <ul className={styles._list}>
            {tags?.map((tag: string, index: number) => <li className={styles._item} key={index}>{tag.trim()}</li>)}
        </ul>
    )
}

export default PartyTag;
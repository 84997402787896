import React from 'react';
import { bookingPageConnector, BookingPageProps } from '../bookingPageSlice';
import NoMatch from '../../../pages/404/404';
import { useNavigate } from "react-router-dom";
import styles from './BookingDonePage.module.css';
import { useTranslation } from 'react-i18next';


const BookingDonePage = (state: BookingPageProps) => {

    const { t } = useTranslation();

    let navigate = useNavigate();
    //http://localhost:3000/bookingConfirmation/done?payment_intent=pi_3OExT4JHT6ygutoc0cZULNZR&payment_intent_client_secret=pi_3OExT4JHT6ygutoc0cZULNZR_secret_YmyQshLsP8YUpwV5dL6TB57Q2&redirect_status=succeeded
    if (!state.selectedParty)
        return <NoMatch />;

    return (
        <main>
            <div className={styles._wrapper}>
                <div className={styles._content}>
                    {/*<CheckoutBlock booking={state.booking} selectedParty={state.selectedParty} />*/}
                    <h1 className={styles._title}>{t('You booked a party')}</h1>
                    <p className={styles._text}>{t('Your application has been accepted and is under review. Expect a response within 24 hours by email Johndoe@gmail.com. After confirmation, you will be able to pay for the party')}</p>
                    <button className={styles._action} type={'button'} onClick={() => navigate('/')}>{t('Back to Main Page')}</button>
                </div>
            </div>
        </main>
    )
}

export default bookingPageConnector(BookingDonePage);
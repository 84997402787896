import { createSlice } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../app/store";

const tags: string[] = [
    'Fun Jump',
    'Jump lessen',
    'Pizza',
    'Eten',
    'Bakken',
    'Speeltuin',
    'Klimmen',
    'Kano',
    'Ijsskaten',
    'Zingen',
    'Knutselen',
    'Golfen',
    'Gamen',
    'Dieren',
    'Muziek',
    'Cirsus'
]

export interface TagsState {
    tags?: string[];
    status: 'idle' | 'loading' | 'failed';
}

export const initialState: TagsState = {
    tags: tags,
    status: 'idle',
};

export const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {

    },
});


export const actionsRef = {
}

export const stateRef = (state: RootState) => ({ tags: state.tags.tags, status: state.tags.status });

export const tagsConnector = connect(stateRef, actionsRef)
export type TagsProps = ConnectedProps<typeof tagsConnector>;

export default tagsSlice.reducer;

import PartyExtraOptionDTO from "./PartyExtraOptionDTO";
import PartyOptionDTO from "./PartyOptionDTO"
import VendorDTO from "./VendorDTO";
import PhotoDTO from "./PhotoDTO";
import CommentDTO from "./CommentDTO";

export default class PartyDTO {
  id: number = -1;
  title: string = '';
  location: string = '';
  description: string = '';
  extraInformation: string = '';

  public isVendorsPlace: boolean = true;

  vendor: VendorDTO;
  photos?: PhotoDTO[];
  options: PartyOptionDTO[] = [];
  extraOptions: PartyExtraOptionDTO[] = [];
  comments?: CommentDTO[];
  cover: PhotoDTO | null;

  vendorParties?: PartyDTO[];

  // price?: PriceDTO; //???
  rating?: number; //???
  tags?: string[]; //???

  constructor(source: any) {
    Object.assign(this, source);

    this.vendor = new VendorDTO(source.vendor);

    this.cover = new PhotoDTO(source.cover);

    if (source.photos !== null && source.photos !== undefined) {
      this.photos = source.photos.map((item: any) => new PhotoDTO(item));
    }

    if (source.options !== null && source.options !== undefined) {
      this.options = source.options.map((item: any) => new PartyOptionDTO(item));
    }

    if (source.extraOptions !== null && source.extraOptions !== undefined) {
      this.extraOptions = source.extraOptions.map(
        (item: any) => new PartyExtraOptionDTO(item)
      );
    }
  }
}


export const getCheapestOption = (party: PartyDTO): PartyOptionDTO | undefined => {
  if (!party.options)
    return undefined;

  let partyOption: PartyOptionDTO | undefined = undefined;
  for (let option of party.options) {
    if (partyOption === undefined || partyOption.price > option.price) {
      partyOption = option;
    }
  }

  return partyOption;
}
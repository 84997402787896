export const WEB_URL: string = process.env.REACT_APP_KIDSVENU_WEB || "http://localhost:3000";
export const VENDOR_URL : string = process.env.REACT_APP_KIDSVENU_VENDOR || "https://vendor.kidsvenu.nl";

export const PARTY_ROUTE: string = "/party/:partyId";
export const PARTY_BOOK_ROUTE: string = "/party/:partyId/book";
export const PARTY_BOOK_DONE_ROUTE: string = "/party/:partyId/book/done";
export const SEARCH_ROUTE: string = "/search";
export const USER_REGISTRATION_ROUTE: string = "/user_registration";
export const COMPANY_REGISTRATION_ROUTE: string = "/company_registration";
export const ACTIVATION_ROUTE: string = "/activation/:activationCode";
export const BOOKING_CONFIRMATION_ROUTE: string = "/bookingConfirmation/:bookingId";
export const BOOKING_CONFIRMATION_DONE_ROUTE: string = "/bookingConfirmation/done";


export const getFullUrl = (subUrl: string) =>
  WEB_URL + subUrl;

export const GET_PARTY_URL = (partyId: string | number, full: boolean = false) => (full ? WEB_URL : "") + PARTY_ROUTE.replace(":partyId", "" + partyId);
export const GET_PARTY_BOOK_URL = (partyId: string | number, full: boolean = false) => (full ? WEB_URL : "") + PARTY_BOOK_ROUTE.replace(":partyId", "" + partyId);
export const GET_PARTY_BOOK_DONE_URL = (partyId: string | number, full: boolean = false) => (full ? WEB_URL : "") + PARTY_BOOK_DONE_ROUTE.replace(":partyId", "" + partyId);
export const GET_SEARCH_URL = (full: boolean = false) => (full ? WEB_URL : "") + SEARCH_ROUTE;
export const GET_BOOKING_CONFIRMATION_URL = (bookingId: string | number, full: boolean = false) => (full ? WEB_URL : "") + BOOKING_CONFIRMATION_ROUTE.replace(":bookingId", "" + bookingId);
export const GET_BOOKING_CONFIRMATION_DONE_URL = (full: boolean = false) => (full ? WEB_URL : "") + BOOKING_CONFIRMATION_DONE_ROUTE;
// import Tags from "../features/tags/Tags"
import PartyGrid from "../features/partyGrid/PartyGrid"

const Home = () => {
  return (
    <main>
        <section>
            <h1 className="visually-hidden">Some Main Page Title For SEO</h1>
            <div className="container">
                {/*<Tags />*/}
                <PartyGrid />
            </div>
        </section>
    </main>
  )
}

export default Home;

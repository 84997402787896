import React from "react";
import styles from './FooterLinks.module.css'

let linksArr = [
    {
        text: 'DEFAULT LINK TEXT',
        href: '#',
        itemClassName: '',
        linkClassName: ''
    }
]

const FooterLinks = ({links = linksArr, listClassName = ''}) => {
    return (
        <ul className={listClassName.length ? (styles._list + ' ' + listClassName) : styles._list}>
            {links.length ? links.map((link) =>
                <li key={link.text} className={
                    link.itemClassName
                    ? (styles._item + ' ' + link.itemClassName)
                    : styles._item
                }>
                    <a href={link.href} className={
                        link.linkClassName
                        ? (styles._link + ' ' + link.linkClassName)
                        : styles._link
                    }>{link.text}</a>
                </li>
            ) :
                ''
            }
        </ul>
    )
}

export default FooterLinks;

import React, {useState} from "react";
import {useTranslation} from 'react-i18next';
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";

import styles from "./CompanyRegistration.module.css";
import {CompanyRegistrationDTO, ValidationMessage} from "../../types/CompanyRegistrationDTO";
import {CheckBoxInput, PasswordInput, TextAreaInput, TextInput} from "../../common/Input";
import * as registrationService from '../../service/registration_service'
import {useShowModalMessage} from "../../components/modal/ModalWindowContext";

const CompanyRegistration = () => {

  const navigate = useNavigate();
  const showMessage = useShowModalMessage();
  const {t} = useTranslation();

  const [data, setData] = useState(new CompanyRegistrationDTO());
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState(new ValidationMessage());


  const handleChange = (newValue: string | boolean | number, name: string) => {
    // setData({...data, [e.target.name]: e.target.value});
    console.log(`data change: ${name} => ${newValue}`);

    if (name === 'password2') {
      setPassword2("" + newValue);
    } else {
      setData({...data, [name]: newValue});
    }

    if (name !== 'country' && name !== 'description') {
      validate(name, "" + newValue);
    }

    // setSubmitted(false);
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (validate()) {
      let result = await registrationService.registerCompany(data);
      let title = t('Company registration service');

      if (!result || result instanceof AxiosError) {
        let message = t('Error on company registration');

        if (result instanceof AxiosError) {
          message += ':<br/>' + result.response?.data.message;
        }
        showMessage.error({
          title,
          message,
        });
      } else {
        showMessage.info({
          title,
          message: t('Company registered successfully!<br/>Please check your email'),
          icon: "info",
          //navigate to index page on success
          okHandler: () => navigate("/"),
        });
      }
    } else {
      setData({...data});
    }
  };

  const getIsEmpty = (messages: ValidationMessage): boolean => {
    return !messages.companyName
      && !messages.description
      && !messages.userName
      && !messages.phone
      && !messages.address
      && !messages.email
      && !messages.kvkNumber
      && !messages.iban
      && !messages.password
      && !messages.password2
      && !messages.agreement;
  }

  const validate = (property?: string, newValue?: string | boolean): boolean => {
    let result = errors;

    if (!property) {
      result.companyName = validateStringProperty('companyName', newValue as string | undefined);
      result.description = validateStringProperty('description', newValue as string | undefined);
      result.userName = validateStringProperty('userName', newValue as string | undefined);
      result.phone = validateStringProperty('phone', newValue as string | undefined);
      result.address = validateStringProperty('address', newValue as string | undefined);
      result.email = validateStringProperty('email', newValue as string | undefined);
      result.kvkNumber = validateStringProperty('kvkNumber', newValue as string | undefined);
      result.password = validateStringProperty('password', newValue as string | undefined);
      result.iban = validateStringProperty('iban', newValue as string | undefined);
      result.password2 = undefined;
      if (getIsEmpty(result)) {
        result.password2 = validateStringProperty('password2', newValue as string | undefined);
      }

      result.agreement = validateBoolProperty('agreement', newValue as boolean | undefined);

      setErrors(result);
      return getIsEmpty(result);
    }

    if (property === "agreement") {
      result = {...result, [property]: validateBoolProperty(property, newValue as boolean | undefined)};
    } else {
      result = {...result, [property]: validateStringProperty(property, newValue as string | undefined)};
    }

    setErrors(result);
    return !!{...result}[property];
  }

  const validateReqexp = (regex: string, value: string): boolean =>
    (new RegExp(regex)).test(value)

  const validateBoolProperty = (property: string, newValue?: boolean): string | undefined => {
    const value = newValue ?? ({...data}[property] ?? false);

    if (property === "agreement") {
      return !value ? t('Field is required') : undefined;
    }

    return undefined;
  }

  const validateStringProperty = (property: string, newValue?: string): string | undefined => {

    if (property === "password2") {
      if ((newValue ?? password2) !== data.password) {
        return t('Passwords do not match');
      }
      return undefined;
    }

    let value = newValue ?? ('' + ({...data}[property] ?? ''));
    let message = "";

    switch (property) {
      case "companyName":
        if (!message && !value.length) message = t('Business name is required!');
        if (!message && value.length < 3) message = t('Business name can not be shorter than 3 characters!');
        break;
      case "description":
        break;
      case "userName":
        if (!message && !value.length) message = t('User name is required!');
        break;
      case "phone":
        if (!message && !validateReqexp('^[0|+][\\d-\\s]{8,}$', value)) message = t('Invalid phone number format!');
        break;
      case "address":
        const minLength = 16;
        if (!message && !value) message = t('Address is required!');
        if (!message && value.length < minLength) message = t('Address can not be shorter than {{minLength}} characters!', {minLength: minLength});
        break;
      case "email":
        if (!message && !value.length) message = t('Email is required!');
        if (!message && !validateReqexp('^[\\w.]{4,20}@[a-z]{2,}[.][a-z]{2,5}(?=\\S*$)$', value)) message = t('Incorrect Email format!');
        break;
      case "kvkNumber":
        if (!message && !value.length) message = t('KVK number is required!');
        if (!message && !validateReqexp('^\\d{8}$', value)) message = t('KVK number should contain 8 digits!');
        break;
      case "iban":
        if (!message && !value.length) message = t('IBAN is required!');
        const regex = "^(BE(?:\\d *){13}\\d|NL(?:\\d *){2}(?:[A-z] *){4}(?:\\d *){10}|FR(?:\\d *){12}(?:[\\dA-z] *){11}(?:\\d *){2})$";
        if (!message && !validateReqexp(regex, value)) message = t('IBAN should follow BE, NL or FR format');
        break;
      case "password":
        if (!message && !value.length) message = t('Password is required!');
        if (!message && !validateReqexp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$", value)) message = t('Password must contain at least 8 characters, at least 1 uppercase and 1 lowercase, at least 1 number')
        break;
    }

    if (message === '') return undefined;

    return message;
  }

  return (
    <main>
      <div className={'container'}>
        <div className={styles.form}>
          <h1>Company registration</h1>
          <form className={styles.form_content}>
            <TextInput title={t('Business name')} name="companyName" value={data.companyName}
                       errorMessage={errors.companyName} onChange={handleChange}
                       description={t('Enter the company name as registered with the Chamber of Commerce (KvK) here')}/>
            <TextInput title={t('KVK number')} name="kvkNumber" value={data.kvkNumber}
                       errorMessage={errors.kvkNumber} onChange={handleChange}
                       description={t('8-digit KvK number')}/>
            <TextInput title={t('IBAN')} name="iban" value={data.iban}
                       errorMessage={errors.iban} onChange={handleChange}
                       description={t('Required for registration')}/>
            <TextInput title={t('Address')} name="address" value={data.address}
                       errorMessage={errors.address} onChange={handleChange}
                       description={t("Enter the address according to the following format: street name, house number, zip code, city, country")}/>
            <TextInput title={t('Phone')} name="phone" value={data.phone}
                       errorMessage={errors.phone} onChange={handleChange}
                       description={t("Enter phone number, including country code beginning with '+'")}/>
            <TextInput title={t('User name')} name="userName" value={data.userName}
                       errorMessage={errors.userName} onChange={handleChange}
                       description={t('Contact person')}/>
            <TextInput title={t('Email')} name="email" value={data.email}
                       errorMessage={errors.email} onChange={handleChange}/>
            <PasswordInput title={t('Password')} name="password" value={data.password}
                           errorMessage={errors.password} onChange={handleChange}
                           description={t('Terms, numbers, letters, characters')}/>
            <PasswordInput title={t('Repeat password')} name="password2" value={password2}
                           errorMessage={errors.password2} onChange={handleChange} lastField={true}/>
            <TextAreaInput title={t('About company')} name='description' value={data.description}
                           onChange={handleChange}
                           descriptionKey='Company description'/>
            <div className={styles.from_grid} style={{paddingTop: '10px', paddingBottom: '10px'}}>

              <CheckBoxInput
                title={t('I agree with terms and conditions')}
                name="agreement"
                value={data.agreement}
                onChange={handleChange}
                errorMessage={errors.agreement}
              />
              <CheckBoxInput
                title={t('Country')}
                name="country"
                value={data.country}
                onChange={handleChange}
                errorMessage={errors.country}
              />
            </div>
            <button onClick={handleSubmit} className={styles.btn} type="submit">
              {t('Register')}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
}

export default CompanyRegistration;
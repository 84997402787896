import React, {ChangeEvent, useEffect, useState} from "react";
import styles from "./UserRegistration/UserRegistration.module.css";

const Activation = () => {


  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  // States for checking the errors
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // TODO(Oleg): check the code to redirect if the activation code is invalid
    // if (activationCode)
    //   getSingle(USER_CHECK_ACTIVATION_CODE, activationCode).then((value) => {
    //     if (!value) navigate("/")
    //   }, () => {
    //     navigate("/")
    //   });
  });

  // Handling the name change
  const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setSubmitted(false);
  };

  // Handling the email change
  const handlePassword2 = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword2(e.target.value);
    setSubmitted(false);
  };


  // Handling the form submission
  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    let message = validatePassword(password, password2);

    if (message) {
      setError(message);
    } else {
      // await
    }

    console.log(password);
  };

  // Showing success message
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}
      >

      </div>
    );
  };

  // Showing error message if error is true
  const errorMessage = () => {
    return (
      <div
        className={styles.error}
        style={{display: error ? "" : "none",}}
      >
        <h4>{error}</h4>
      </div>
    );
  };

  const validatePassword = (password: string, password2: string): string => {
    if (!password) return 'Password is required!';
    if (password2 !== password) return 'Passwords are not equal';

    return "";
  }

  return (
    <div className={styles.form}>
      <div>
        <h1>User activation</h1>
      </div>

      {/* Calling to the methods */}
      <div className={styles.messages}>
        {errorMessage()}
        {successMessage()}
      </div>

      <form>
        {/* Labels and inputs for form data */}
        <label className={styles.label}>Password</label>
        <input
          onChange={handlePassword}
          className={styles.input}
          value={password}
          type="password"
        />

        <label className={styles.label}>Repeat password</label>
        <input
          onChange={handlePassword2}
          className={styles.input}
          value={password2}
          type="password"
        />

        <label className={styles.label} style={{color: '#FF5577'}}>ADD CAPTCHA</label>

        <button onClick={handleSubmit} className={styles.btn} type="submit">Activate</button>
      </form>
    </div>
  );
}

export default Activation;
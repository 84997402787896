import styles from './Button.module.css';

export enum ButtonModification{bordered, filled, shadow, fluid}

type buttonType = {
    href?: string;
    modification?: ButtonModification[] | ButtonModification;
    iconBefore?: string;
    text?: string;
    iconAfter?: string;
    type?: 'submit' | 'button' | 'reset' | undefined;
    action?: Function;
}

const Button = ({href, modification, iconBefore, text, iconAfter, type, action }: buttonType) => {
    const convertButtonModificationToStyleClassName = (item: ButtonModification) => {
        switch (item) {
            case ButtonModification.bordered:
                return styles.__bordered;
            case ButtonModification.filled:
                return styles.__filled;
            case ButtonModification.shadow:
                return styles.__shadow;
            case ButtonModification.fluid:
                return styles.__fluid;
        }
    }

    const convertModificationToClassList = (source?: ButtonModification[] | ButtonModification): string => {
        let result = source === undefined ? [] :
            Array.isArray(source) ?
                source.map(convertButtonModificationToStyleClassName) : [convertButtonModificationToStyleClassName(source)];
        result.unshift(styles._primary);
        return result.join(' ');
    }

    return (
        <>
            {href
                ?
                <a
                    className={convertModificationToClassList(modification)}
                    href={href}
                >
                    {/*{iconBefore ? <{iconBefore}/> : ''}*/}
                    {text && <span>{text}</span>}
                    {/*{iconAfter ? <{iconAfter} /> : ''}*/}
                </a>
                :
                <button
                    className={convertModificationToClassList(modification)}
                    type={type === undefined ? 'button' : type}
                    onClick={(event) => { if(action !== undefined) action(event)}}
                >
                    {/*{iconBefore ? <{iconBefore}/> : ''}*/}
                    {text && <span>{text}</span>}
                    {/*{iconAfter ? <{iconAfter} /> : ''}*/}
                </button>
            }
        </>
    )
}

export default Button;

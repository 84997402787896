import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../app/store";
import PartyDTO from "../../types/PartyDTO";
import API from "../../app/api";

const loadParties = createAsyncThunk(
    'vendor/loadParties',
    async (data?: any) => {
      const {search, page, pageSize} = data;
      return await API.loadParties(search, page, pageSize);
    }
  );


export interface PartyGridState {
    parties?: PartyDTO[];
    status: 'idle' | 'loading' | 'failed';
}

export const initialState: PartyGridState = {
    parties: undefined,
    status: 'idle',
};

export const partyGridSlice = createSlice({
    name: 'parties',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder) => {
        builder
    
          .addCase(loadParties.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(loadParties.fulfilled, (state, action) => {
            state.status = 'idle';
            // console.log(action);
            state.parties = action.payload.sort((a: PartyDTO, b: PartyDTO) => a.id < b.id ? -1 : 1);
          })
          .addCase(loadParties.rejected, (state) => {
            state.status = 'failed';
          })
      },
});


export const actionsRef = {
    loadParties
}

export const stateRef = (state: RootState) => ({ parties: state.parties.parties, status: state.parties.status});

export const partyGridConnector = connect(stateRef, actionsRef)
export type PartyGridProps = ConnectedProps<typeof partyGridConnector>;

export default partyGridSlice.reducer;

import React from "react";
import styles from './Footer.module.css';
import FooterLinks from './footerLinks/FooterLinks';
import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";

const footerLinks = [
    {
        text: '',
        href: '#',
        itemClassName: '',
        linkClassName: ''
    }
]

const Footer = ({listClassName = '', links = footerLinks}) => {
    return (
        <footer className={styles._main}>
            <div className="container">
                <div className={styles._wrapper}>
                    <div className={styles._content}>
                        <p className={styles._copyright}><span>&#169;</span> 2024 Kidsvenu</p>
                        <div className={styles._links}>
                            <FooterLinks listClassName={listClassName} links={links} />
                        </div>
                    </div>
                    <LanguageSwitcher/>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import React from "react";
import styles from './SearchForm.module.css'
import {useState} from "react";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";


const Form = () => {
  let [searchString, setSearchString] = React.useState('');

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  
  const { t } = useTranslation();

  function onSearch(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    let params: { [key: string]: string } = {};
    if (searchString) {
      params['search'] = encodeURI(searchString);
    }

    navigate({
      pathname: '/',
      search: `?${createSearchParams(params)}`,
    });
  }

  React.useEffect(() => {
    let searchParamString = searchParams.get('search') ?? '';
    setSearchString(searchParamString);
  }, [searchParams]);


  return (
    <form className={styles._main} action="/Search" method="get">
      <label className={styles._label}>
        <input className={styles._input} type="text" aria-label="search input area"
               placeholder={t("Search")}
               value={searchString}
               onChange={(event) => setSearchString(event.target.value)}
          // onInput={() => onSearch}
        />
      </label>
      <button className={styles._submit} type="submit" aria-label="search submit button"
              onClick={onSearch} value={searchString}>
        <FontAwesomeIcon icon="search"/>
      </button>
    </form>
  )
}

const SearchForm = () => {

  let [searchString, setSearchString] = React.useState('');
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    let searchParamString = searchParams.get('search') ?? '';
    setSearchString(searchParamString);
    setOpenModal(false);
  }, [searchParams]);


  const body = document.body;

  return (
    <div className={styles._wrapper}>
      <div className={styles._desktop}>
        <Form/>
      </div>
      <button
        onClick={() => {
          setOpenModal(!openModal);
          body.style.overflow = 'hidden';
        }}
        className={styles._open_form}
        type="button"
        aria-label="open search form"
      >
          <span className={styles.text}>{t('Search')}{!searchString ? '': searchString}</span>
          <span className={styles.icon}>
              <FontAwesomeIcon icon="search"/>
          </span>

      </button>
      {openModal &&
        <div
          aria-labelledby="search modal"
          aria-describedby="search modal"
        >
          <div className={styles._modal_container}>
            <button className={styles._close_modal}
                    onClick={() => {
                      setOpenModal(!openModal);
                      body.style.removeProperty('overflow');
                    }}
            >
              <span></span><span></span>
            </button>
            <Form/>
          </div>
        </div>}
    </div>
  )
}

export default SearchForm;

// import React, { useState } from "react";
import styles from './Modal.module.css'
import { ReactComponent as Xmark } from '../../icons/xmark.svg';
import { ReactComponent as Alert } from '../../icons/alert.svg';
import { ReactComponent as Error } from '../../icons/error.svg';
import { ReactComponent as Info } from '../../icons/info.svg';
import parse from 'html-react-parser'
import React from "react";
import Button, {ButtonModification} from "../button/Button";
import {useCloseModalMessage, ModalCloseType} from "./ModalWindowContext";
import {t} from "i18next";


type modalType = {
    isShow?: boolean;
    title?: string;
    isClosing?: boolean;
    content?: string;
    icon?: 'info' | 'alert' | 'error';
    cancelButton?: {
        modification?: ButtonModification[] | ButtonModification;
        type?: 'submit' | 'button' | 'reset' | undefined;
        text?: string;
    };
    okButton?: {
        modification?: ButtonModification[] | ButtonModification;
        type?: 'submit' | 'button' | 'reset' | undefined;
        text?: string;
    };
}

const modalDefault = {
    isShow: false,
    title: t('System message'),
    isClosing: false,
    okButton: {
        modification: ButtonModification.filled,
        type: 'button',
        text: 'OK',
    }
} as modalType;
const Modal = ({ isShow, title, isClosing, content, icon, cancelButton, okButton }: modalType) => {
    const closeMessage = useCloseModalMessage();
    return (
        <>
            {isShow &&
                <div className={styles._main}>
                    <div className={styles._wrapper}>
                        {(isClosing ?? modalDefault.isClosing) || (title ?? modalDefault.title) ?
                            <div className={styles._heading}>
                                {(title ?? modalDefault.title) &&
                                    <p className={styles._title}>{(title ?? modalDefault.title)}</p>
                                }
                                {(isClosing ?? modalDefault.isClosing) &&
                                    <button
                                        className={styles._close_button}
                                        type={'button'}
                                        onClick={() => closeMessage()}
                                    >
                                        <Xmark />
                                    </button>
                                }
                            </div>
                        : ''}
                        {(content ?? modalDefault.content) || (icon ?? modalDefault.icon) ?
                            <div className={styles._content}>
                                {(icon ?? modalDefault.icon) &&
                                    <div className={styles._icon_wrapper}>
                                        {(icon ?? modalDefault.icon) === 'alert'
                                            ? <Alert />
                                            : (icon ?? modalDefault.icon) === 'error'
                                                ? <Error />
                                                : <Info />
                                        }
                                    </div>
                                }
                                {(content ?? modalDefault.content) &&
                                    <div className={styles._text_wrapper}>
                                        {parse((content ?? modalDefault.content) ?? '')}
                                    </div>
                                }
                            </div>
                        : ''}
                        {(cancelButton ?? modalDefault.cancelButton) || (okButton ?? modalDefault.okButton) ?
                            <div className={styles._actions}>
                                {(cancelButton ?? modalDefault.cancelButton) &&
                                    <Button
                                        modification={(cancelButton ?? modalDefault.cancelButton)?.modification ?? ButtonModification.bordered}
                                        text={(cancelButton ?? modalDefault.cancelButton)?.text}
                                        type={(cancelButton ?? modalDefault.cancelButton)?.type}
                                        action={() => closeMessage(ModalCloseType.cancel)}
                                    />
                                }
                                {(okButton ?? modalDefault.okButton) &&
                                    <Button
                                        modification={(okButton ?? modalDefault.okButton)?.modification ?? ButtonModification.filled}
                                        text={(okButton ?? modalDefault.okButton)?.text}
                                        type={(okButton ?? modalDefault.okButton)?.type}
                                        action={() => closeMessage(ModalCloseType.ok)}
                                    />
                                }
                            </div>
                        : ''}
                    </div>
                </div>
            }
        </>
    )
}

export default Modal;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import PartyDTO, {getCheapestOption} from "../../types/PartyDTO";
import styles from './PartyCard.module.css';
import PartyOptionDTO from '../../types/PartyOptionDTO';
// import {ReactComponent as HeartIcon} from '../../icons/heart.svg';
// import useMediaSizes from '../../common/useMediaSizes';
// import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import ImageOverlay from '../../components/imageOverlay/ImageOverlay';
import Rating from "../../components/rating/Rating";
import {getImageUrl, ImageFormat, ImageSize} from "../../utils/ImageUtils";

type PartyCardProps = {
  party: PartyDTO,
  modifier?: any
};

const PartyCard = (props: PartyCardProps) => {

  // const {small, medium} = useMediaSizes();
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [cheapestOption, setCheapestOption] = React.useState<PartyOptionDTO>();

  React.useEffect(() => {
    setCheapestOption(getCheapestOption(props.party));
  }, [props.party, setCheapestOption])

  const getCover = (): string | undefined => {
    let result: string | undefined = undefined;
    if (props.party.cover) {
      result = props.party.cover.url;
    }

    if (!result && props.party.photos && props.party.photos.length > 0) {
      result = props.party.photos[0]?.url;
    }

    if (result) {
      return result;
    }

    return undefined;
  }

  return (
    <div className={props.modifier? styles._card + ' ' + styles._search_page : styles._card}
      // onClick={() => navigate("/party/" + party.id)}
    >
      <div className={styles._picture}>
        <ImageOverlay
            src={getCover() !== undefined
                ? getImageUrl(getCover(), new ImageSize(340 + 'x' + 336), ImageFormat.JPG)
                : '/placeholder.svg'}
            src2x={getImageUrl(getCover(), new ImageSize(340 * 2 + 'x' + 336 * 2), ImageFormat.JPG)}
            srcWebp={getImageUrl(getCover(), new ImageSize(340 + 'x' + 336), ImageFormat.WEBP)}
            srcWebp2x={getImageUrl(getCover(), new ImageSize(340 * 2 + 'x' + 336 * 2), ImageFormat.WEBP)}
            width={340}
            height={336}
            alt={props.party.title}/>
      </div>
      <div className={styles._caption}>
        <div className={styles._caption_heading}>
          <div className={styles._rating_wrapper}>
            <Rating rating={props.party.rating} review={0} tooltipPlace={'top-start'}/>
          </div>
          <h3 className={styles._title}>
            <a className={styles._link}
               href={"/party/" + props.party.id}
               aria-label={props.party.title + '' + props.party.location}
               onClick={() => navigate("/party/" + props.party.id)}
            >
              <span>{props.party.title}</span>
              <span className={styles._location}>{props.party.location}</span>
            </a>
          </h3>
        </div>
        {props.party.tags && props.party.tags.length &&
            (<div style={{opacity: '0.5', marginTop: 3}}>{props.party.tags?.join(', ')}</div>)
        }
      </div>
      <p className={styles._price}>{cheapestOption && (
          cheapestOption.priceType === 'pp' ?
              <span><strong>€{cheapestOption.price}</strong>{t(' p.p.')}</span>
              :
              <span>{t('v.a. ')}<strong>€{cheapestOption.price}</strong></span>
      )}
      </p>
      {/*<button className={styles._button} type="button"*/}
      {/*        aria-label={'Add to favorites ' + props.party.title + ' ' + props.party.location}>*/}
      {/*  <HeartIcon aria-hidden="true"/>*/}
      {/*</button>*/}
    </div>
  )
}

export default PartyCard;

import styles from './Input.module.css';
import classNames from "classnames";
import React from "react";
import { Trans } from 'react-i18next';

type InputProps = {
  title: string;
  description?: string;
  descriptionKey?: string;
  placeholder?: string;
  name: string;
  value?: string | number | boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  readonly?: boolean;
  lastField?: boolean;
  min?: number;
  max?: number;
  errorMessage?: string;
  key?: string | number | boolean;
  checked?: boolean;
}

type InputPropsInternal = {
  internalOnChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;

  inputType: 'number' | 'text' | "password" | "checkbox" | "radio" | "textarea";
}

type TextInputProps = {
  onChange?: (newValue: string | boolean, name: string) => void;
}

type NumberInputProps = {
  onChange?: (newValue: number, name: string) => void;
}

export const NumberInput = ({
  title,
  name,
  value,
  onChange,
  onClick,
  readonly,
  lastField,
  min,
  max,
  errorMessage
}: InputProps & NumberInputProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange)
      onChange(+event.target.value, event.target.name)
  }

  return (
    <Input inputType="number" min={min} max={max} errorMessage={errorMessage} title={title} name={name} value={value}
      internalOnChange={changeHandler} onClick={onClick} readonly={readonly} lastField={lastField} />
  )
}

export const TextInput = ({
  title,
  description,
  descriptionKey,
  placeholder,
  name,
  value,
  onChange,
  onClick,
  readonly,
  lastField,
  errorMessage
}: InputProps & TextInputProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange)
      onChange(event.target.value, event.target.name)
  }

  return (
    <Input inputType="text" description={description} descriptionKey={descriptionKey} title={title} placeholder={placeholder} name={name} value={value} internalOnChange={changeHandler} onClick={onClick}
      readonly={readonly} lastField={lastField}
      errorMessage={errorMessage} />
  )
}

export const CheckBoxInput = ({
  title,
  name,
  value,
  onChange,
  onClick,
  readonly,
  lastField,
  errorMessage,
}: InputProps & TextInputProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange)
      onChange((event as React.ChangeEvent<HTMLInputElement>).target.checked, event.target.name)
  }

  return (
    <Input inputType="checkbox" title={title} name={name} value={value} internalOnChange={changeHandler}
      onClick={onClick} readonly={readonly} lastField={lastField}
      errorMessage={errorMessage} />
  )
}

export const PasswordInput = ({
  title,
  description,
  descriptionKey,
  placeholder,
  name,
  value,
  onChange,
  onClick,
  readonly,
  lastField,
  errorMessage
}: InputProps & TextInputProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange)
      onChange(event.target.value, event.target.name)
  }

  return (
    <Input inputType="password" title={title} description={description} descriptionKey={descriptionKey} placeholder={placeholder} name={name} value={value} internalOnChange={changeHandler}
      onClick={onClick} readonly={readonly} lastField={lastField}
      errorMessage={errorMessage} />
  )
}

export const RadioButtonInput = ({
  title,
  description,
  descriptionKey,
  name,
  value,
  onChange,
  onClick,
  readonly,
  lastField,
  errorMessage,
  checked,
}: InputProps & TextInputProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange)
      onChange((event as React.ChangeEvent<HTMLInputElement>).target.checked, event.target.name)
  }

  return (
    <Input inputType="radio"
      title={title}
      description={description}
      descriptionKey={descriptionKey}
      name={name}
      value={value}
      internalOnChange={changeHandler}
      onClick={onClick}
      readonly={readonly}
      lastField={lastField}
      errorMessage={errorMessage}
      checked={checked}
    />
  )
}

export const TextAreaInput = ({
  title,
  description,
  descriptionKey,
  placeholder,
  name,
  value,
  onChange,
  onClick,
  readonly,
  lastField
}: InputProps & TextInputProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange)
      onChange(event.target.value, event.target.name)
  }

  return (
    <Input inputType="textarea" title={title} placeholder={placeholder} description={description} descriptionKey={descriptionKey} name={name} value={value} internalOnChange={changeHandler}
      onClick={onClick} 
      readonly={readonly} lastField={lastField} />
  )
}

const Input = ({
  inputType,
  title,
  description,
  descriptionKey,
  placeholder,
  name,
  value,
  internalOnChange,
  onClick,
  readonly,
  lastField,
  min,
  max,
  errorMessage,
  checked,
}: InputProps & InputPropsInternal) => {
  const iphone = /iPhone/i.test(navigator.userAgent);

  return (
    <div className={name === "country" ? styles.inputBlockCountry : styles.inputBlock} onClick={onClick}
      style={lastField ? {} : { borderBottom: 0 }}>
      <label className={inputType === "checkbox"
        ? styles._checkbox_label
        : inputType === "radio"
          ? styles._radio_label
          : styles.input}>
        {inputType === "checkbox" && <input
          className={classNames(
            styles._checkbox_input,
            (!!errorMessage ? styles.error : ``),
          )}
          type="checkbox" name={name}
          checked={value as boolean}
          onChange={internalOnChange}
          readOnly={readonly} min={min} max={max} />
        }
        {inputType === "checkbox" && !iphone &&
          <span className={classNames(styles._checkbox_pseudo, (!!errorMessage ? styles.error : ``))}>&#10004;</span>
        }
        {inputType === "checkbox" && iphone &&
          <span className={classNames(styles._checkbox_pseudo_iphone, (!!errorMessage ? styles.error : ``))}></span>
        }
        {inputType !== "radio" &&
          <span className={classNames(inputType === "checkbox" ? styles._checkbox_text : styles.inputLabel,
            inputType === "checkbox" && !!errorMessage ? styles.error : ``)}>
            {title}
          </span>
        }
        {inputType === "radio" &&
          <span className={styles._radio_text_wrapper}>
            <span className={styles._radio_title}>{title}</span>
            {description ? <span className={styles._radio_description}>{description}</span> : ''}
            {descriptionKey && <span className={styles._description}><Trans i18nKey={descriptionKey}/></span>}
          </span>
        }
        {inputType !== "checkbox" && inputType !== "textarea" &&
          <input className={classNames(styles.inputField, (!!errorMessage ? styles.error : ``),)}
            type={inputType} placeholder={placeholder} name={name}
            checked={checked}
            value={typeof value == "boolean" ? '' : value}
            onChange={internalOnChange} readOnly={readonly} min={min} max={max} />
        }
        {inputType === "textarea" &&
          <div className={styles._textarea_wrapper}>
            <textarea className={styles._textarea} onChange={internalOnChange} name={name} placeholder={placeholder} />
          </div>
        }
        {inputType !== "radio" && inputType !== "checkbox" ?
          <span className={styles._description_wrapper}>
            {description && <span className={styles._description}>{description}</span>}
            {descriptionKey && <span className={styles._description}><Trans i18nKey={descriptionKey}/></span>}
            {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
          </span> : errorMessage ? <span className={styles.errorMessage}>{errorMessage}</span> :
            ''
        }
      </label>
    </div>
  )
}

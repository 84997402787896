import {Context, createContext, useContext, useReducer} from 'react';
import {ButtonModification} from "../button/Button";
import Modal from "./Modal";

const ModalWindowContext: Context<any> = createContext(null);
const ModalWindowDispatchContext: Context<any> = createContext(null);

export function ModalWindowProvider({children}: any) {
  const [state, dispatch] = useReducer(
    modalWindowReducer,
    stateDefault
  );

  return (
    <ModalWindowContext.Provider value={state}>
      <ModalWindowDispatchContext.Provider value={dispatch}>
        <Modal isClosing={state.isClosing}
               isShow={state.visible}
               title={state.title}
               content={state.message}
               cancelButton={state.cancelButton}
               okButton={state.okButton}
               icon={state.icon}/>
        {children}
      </ModalWindowDispatchContext.Provider>
    </ModalWindowContext.Provider>
  );
}

export function useTasks() {
  return useContext(ModalWindowContext);
}

function useModalWindowDispatch() {
  return useContext(ModalWindowDispatchContext);
}

function modalWindowReducer(state: ModalWindowState, action: any) {
  switch (action.type) {
    case 'showMessage': {
      return {...stateDefault, ...action.payload, visible: true};
    }
    case 'close': {
      if (state.declineHandler != null) {
        state.declineHandler();
      }

      return {...stateDefault};
    }
    case 'ok': {
      if (state.okHandler != null) {
        state.okHandler();
      }

      return {...stateDefault};
    }
    case 'cancel': {
      if (state.cancelHandler != null) {
        state.cancelHandler();
      }

      return {...stateDefault};
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export function useShowModalMessage(){
  let dispatch = useModalWindowDispatch();

  return {
    error: (modalWindowState: ModalWindowState) => {
      dispatch({type: "showMessage", payload: {...modalWindowState, icon: "error"}});
    },
    info: (modalWindowState: ModalWindowState) => {
      dispatch({type: "showMessage", payload: {...modalWindowState, icon: "info"}});
    },
    warning: (modalWindowState: ModalWindowState) => {
      dispatch({type: "showMessage", payload: {...modalWindowState, icon: "alert"}});
    },
  }
  // return
}

export enum ModalCloseType {close, ok, cancel}

export const useCloseModalMessage = () =>
{
  let dispatch = useModalWindowDispatch();
  return (type?: ModalCloseType) =>
  {

    switch (type ?? ModalCloseType.close) {
      case ModalCloseType.close:
        dispatch({type: 'close'});
        break;
      case ModalCloseType.ok:
        dispatch({type: 'ok'});
        break;
      case ModalCloseType.cancel:
        dispatch({type: 'cancel'});
        break;
    }
  }
}


type ModalWindowState = {
  visible?: boolean;
  title?: string;
  message: string;
  isClosing?: boolean;
  content?: string;
  icon?: 'info' | 'alert' | 'warning';
  cancelButton?: {
    modification?: ButtonModification[] | ButtonModification;
    type?: 'submit' | 'button' | 'reset' | undefined;
    text?: string;
  };
  okButton?: {
    modification?: ButtonModification[] | ButtonModification;
    type?: 'submit' | 'button' | 'reset' | undefined;
    text?: string;
  };

  declineHandler?: Function;
  cancelHandler?: Function;
  okHandler?: Function;
}

const stateDefault = {
  visible: false,
  title: 'System message',
  isClosing: false,
  okButton: {
    modification: ButtonModification.filled,
    type: 'button',
    text: 'OK',
  }
} as ModalWindowState;
